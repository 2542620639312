.checkboxLabel {
  margin-top: 4px;
  padding-left: 0;
}

.css-label {
  background-image: url('../img/dark-check-green.png');
}

.css-label-round {
  background-image: url('../img/dark-check-green-round.png');
}
